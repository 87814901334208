import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeProductPreview from "../components/home/home-product-preview"
import HomeVideoExpand from "../components/home/home-video-expand"
import HomeHero from "../components/home/home-hero"
import CallToActionQualify from "../components/call-to-action-qualify/call-to-action-qualify"
import FaqSection from "../components/faq-section"
import StarIcons from "../components/images/star-icons"
import HomeCarousel from "../components/home/home-carousel"
import HomeLoaderLottie from "../components/home/home-loader-lottie"
// import GIF from "./../images/modal-exploded.gif"
import StickyMobileCta from "./../components/call-to-action-qualify/sticky-mobile-cta"
import PhoneSection from "../components/phone-section/phone-section"
import MobilePhoneSection from "../components/phone-section/mobile-phone-section"
import Helmet from "react-helmet"

export default function Index({
  data: { productQueries, faqQueries, bgImgs },
}) {
  const { edges: products } = productQueries
  const { edges: faqs } = faqQueries
  const { edges: bgs } = bgImgs
  const [playVideo, setPlayVideo] = useState(
    process.env.NODE_ENV === "development"
  )

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    xhr.send(data);
}
  useEffect(() => {
    addWebEvent("visit", "index")
  },[]);


  //API REQUEST PRODUCTS WAIT UNTILL RESPONDED TO MOVE FORWARD
  function httpRequest(address, reqType, asyncProc) {
    //var req = new XMLHttpRequest();
    var req = typeof window !== "undefined" ? new XMLHttpRequest() : ""
    if (asyncProc) { 
      req.onreadystatechange = function() { 
        if (this.readyState == 4) {
          asyncProc(this);
        } 
      };
    }
    //req.open(reqType, address, !(!asyncProc));
    typeof window !== "undefined" ? req.open(reqType, address, !(!asyncProc)) : ""
    //req.send();
    typeof window !== "undefined" ? req.send() : ""
  return req;
  }

  var req = httpRequest("https://api.checksolarcost.com/v1/getAllModalProducts", "POST");  // In this example you don't want to GET the full page contents
  const response = req.responseText;
  const products_obj = typeof window !== "undefined" ? JSON.parse(response) : ""
  const product_content = typeof window !== "undefined" ? products_obj.map( node_product  => (
        <div
          key={node_product.id}
          className="lg:w-1/3 w-full border-b border-t lg:py-20"
        >
          <HomeProductPreview
            product={node_product}
          ></HomeProductPreview>
        </div>
      )) : ""

  console.log(product_content)
  
  return (
    <Layout animateHeader={true}>
      {//!playVideo ? (
        //<HomeLoaderLottie onComplete={setPlayVideo}></HomeLoaderLottie>
      //) : null
      }
      <SEO title="Home" />
      <HomeHero play={playVideo}></HomeHero>
  
      
      <section className="relative flex lg:flex-row flex-col justify-center items-center bg-white lg:px-24">
        
        {product_content}
        
      </section>

      {/*<section className="relative flex flex-row justify-center items-center lg:pt-24 lg:pb-0 py-10 lg:p-0 bg-white hidden">
        <div>
          <img src={GIF} alt="home explosion" />
        </div>
        </section>*/}

      <FaqSection faqs={faqs}></FaqSection>
      <section className="relative text-center bg-white">
        <div className="relative text-center py-24 lg:py-40 bg-gray-100">
          <h4 className="m-auto pb-6 lg:px-0 px-6 max-w-4xl m-auto text-gray-600 font-bradford1">
            "I recently had the pleasure of installing solar panels on my home, 
            and it's been an incredibly positive experience. The energy savings 
            are already noticeable, and I'm thrilled to be reducing my carbon 
            footprint. Solar power's reliability, low maintenance, and government 
            incentives make it a fantastic choice for anyone looking to save money 
            and help the environment. If you're thinking about going solar, I can't recommend it enough!"
          </h4>
          <small className="tracking-wide">Kelly M.</small>
          <div className="pt-8 justify-center">
            <StarIcons></StarIcons>
          </div>
        </div>
      </section>
      <section className="relative" style={{ height: "65vh" }}>

        <img
        className="absolute h-full w-screen"
        src="https://media.architecturaldigest.com/photos/64c7bb7ef56f0faa02d217c6/16:9/w_2560%2Cc_limit/GettyImages-1384831593.jpg"
        >
        
        
        
        </img>
      
        <div className="absolute inset-0 absolute inset-0 flex justify-center items-center text-center z-10">
          <div>
            <h3 className="m-auto max-w-md tracking-wide font-light text-white">
              Best quality, best price{" "}
              <span className="font-bradford">price.</span>
            </h3>
            <p className="pt-4 pb-12 lg:px-0 px-6 max-w-xl m-auto tracking-wide font-light text-white">
           Message
            </p>
            <Link
              to="/"
              className="transition transition-delay-longer bg-white text-gray-600 border border-white hover:text-white hover:bg-transparent w-40 mt-12 px-8 py-3 text-center font-bradford1 tracking-wide modal-button"
            >
              How It Works
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query AllPostsQuery {
    productQueries: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "products" } } }
      sort: { fields: frontmatter___title }
    ) {
      edges {
        node {
          frontmatter {
            title
            type
            featuredInfo {
              image {
                childImageSharp {
                  fluid(maxWidth: 750, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              description
              hoverDescription
            }
          }
          fields {
            slug
          }
        }
      }
    }
    faqQueries: allMarkdownRemark(
      filter: { frontmatter: { location: { eq: "home" } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            question
            answer
            order
          }
        }
      }
    }
    bgImgs: allFile(filter: { relativePath: { regex: "/home-bg/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1500, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
